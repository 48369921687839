import { getReviews } from '@cinch-nx/data-trustpilot'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'

import { getHomePageContentData } from './data/content'
import { getHomePageVehiclesData } from './data/vehicles'
import { getPersonalisationEligibility } from './helpers/get-personalisation-eligibility'

import { setTag, traceFunction } from '@core/datadog-server'

async function getServerSidePropsfn(ctx: GetServerSidePropsContext) {
  const isInsideStoryblok = !!ctx.preview

  const [
    vehiclesData,
    story,
    trustpilotData,
    { showPersonalisedComponents, similarListings, recentlyViewedListings },
  ] = await Promise.all([
    getHomePageVehiclesData(),
    getHomePageContentData(isInsideStoryblok),
    getReviews(),
    getPersonalisationEligibility(ctx.req.cookies),
  ])

  if (!story) {
    throw new Error('Unable to fetch `home` story from storyblok')
  }

  setTag('response.code', 200)
  setTag('page.name', 'homepage')
  setTag('page.url', ctx.resolvedUrl)
  setTag('page.rendered', 'SSR')
  setTag(
    'page.params',
    JSON.stringify(
      {
        preview: isInsideStoryblok,
        query: ctx.query,
        showPersonalisedComponents,
      },
      null,
      2,
    ),
  )

  return {
    props: {
      story,
      vehiclesData,
      showPersonalisedComponents,
      trustpilotData,
      preview: isInsideStoryblok,
      recentlyViewedListings,
      similarListings,
      generatedAt: Date.now().toString(),
      layoutProps: {
        hasPromoBanner: false,
      },
    },
  }
}

export const getServerSideProps = traceFunction(
  'homepage.getServerSideProps',
  getServerSidePropsfn,
)

export type HomepageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
>
