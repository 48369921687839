import type { SearchMake, SearchModel } from '@cinch-nx/data-search'
import { getSearchAPIData } from './api'
import type { VehiclesData } from '../types/vehicles-data'

export const getHomePageVehiclesData = async (): Promise<VehiclesData> => {
  const res = await getSearchAPIData()
  const makesData = res.facets.makes

  const makes = makesData.map((make: SearchMake) => ({
    name: make.name,
    stock: make.doc_count,
    models: make.models.map((model: SearchModel) => ({
      name: model.name,
      stock: model.doc_count,
    })),
  }))

  const carTypes = {
    ...res.facets.bodyType,
    electric: res.facets.fuelType.electric,
  }
  const monthlyPriceBucket = {
    ...res.facets.monthlyPriceBucket,
  }
  const priceBucket = {
    ...res.facets.priceBucket,
  }
  const vehiclesData = {
    totalStock: res.searchResultsCount,
    makes,
    carTypes,
    monthlyPriceBucket,
    priceBucket,
  }

  return vehiclesData
}
